import React, { useState } from 'react';
import { Carousel, Modal } from 'react-bootstrap';
import newcars from './data/newCars.json';
import usedcars from './data/usedCars.json';
import CarFilters from './CarFilters';

const Cards = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    make: '',
    model: '',
    year: '',
    price: '',
  });

  // Extract unique values for dropdowns, dynamically filtered based on previous selections
  const makes = [...new Set([...newcars, ...usedcars].map(car => car.make))];

  const filteredModels = [
    ...new Set(
      [...newcars, ...usedcars]
        .filter(car => selectedFilters.make ? car.make === selectedFilters.make : true)
        .map(car => car.model)
    ),
  ];

  const filteredYears = [
    ...new Set(
      [...newcars, ...usedcars]
        .filter(car =>
          (selectedFilters.make ? car.make === selectedFilters.make : true) &&
          (selectedFilters.model ? car.model === selectedFilters.model : true)
        )
        .map(car => car.year)
    ),
  ];

  const filteredPrices = [
    ...new Set(
      [...newcars, ...usedcars]
        .filter(car =>
          (selectedFilters.make ? car.make === selectedFilters.make : true) &&
          (selectedFilters.model ? car.model === selectedFilters.model : true) &&
          (selectedFilters.year ? car.year === selectedFilters.year : true)
        )
        .map(car => car.price.trim())
    ),
  ];

  // Filter cars based on selected filters
  const filteredNewCars = newcars.filter(car => {
    return (
      (selectedFilters.make ? car.make === selectedFilters.make : true) &&
      (selectedFilters.model ? car.model === selectedFilters.model : true) &&
      (selectedFilters.year ? car.year === selectedFilters.year : true) &&
      (selectedFilters.price ? car.price.trim() === selectedFilters.price : true)
    );
  });

  const filteredUsedCars = usedcars.filter(car => {
    return (
      (selectedFilters.make ? car.make === selectedFilters.make : true) &&
      (selectedFilters.model ? car.model === selectedFilters.model : true) &&
      (selectedFilters.year ? car.year === selectedFilters.year : true) &&
      (selectedFilters.price ? car.price.trim() === selectedFilters.price : true)
    );
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle form submission to send email
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedCar) {
      alert('No car selected');
      return;
    }

    const data = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
      car_name: selectedCar.name,
      car_price: selectedCar.price,
    };

    fetch('sendEnquiry.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          alert('Email sent successfully!');
          setFormData({ name: '', email: '', phone: '', message: '' });
          setShowModal(false);
        } else {
          alert('Error sending email.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert(`Error sending email: ${error.message}`);
      });
  };

  // Open modal for inquiry about a specific car
  const handleInquiryClick = (car) => {
    setSelectedCar(car);
    setShowModal(true);
  };

  // Function to render car cards
  const renderCarCards = (carList) => (
    <div className="flex flex-wrap -mx-4">
      {carList.map((car) => (
        <div key={car.id} className="px-4 mb-4 w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <Carousel className="car-carousel">
              {Array.isArray(car.images) && car.images.length > 0 ? (
                car.images.map((image, index) => (
                  <Carousel.Item key={index}>
                    <img
                      src={image}
                      className="w-full h-48 object-cover"
                      alt={`Slide ${index + 1}`}
                    />
                  </Carousel.Item>
                ))
              ) : (
                <Carousel.Item>
                  <img
                    src="/images/cars/placeholder.jpg"
                    className="w-full h-48 object-cover"
                    alt="No images available"
                  />
                  <Carousel.Caption>
                    <h5>No images available for this car</h5>
                  </Carousel.Caption>
                </Carousel.Item>
              )}
            </Carousel>
            <div className="p-4">
              <h3 className="text-xl font-semibold mb-2">{car.name}</h3>
              <p className="text-gray-700">Price: {car.price}</p>
              <p className="text-gray-700">Make: {car.make}</p>
              <p className="text-gray-700">Model: {car.model}</p>
              <p className="text-gray-700">Year: {car.year}</p>
              <button
                className="mt-4 bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded"
                onClick={() => handleInquiryClick(car)}
              >
                Check Availability
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="car-list">
      {/* Filters */}
      <CarFilters
        makes={makes}
        models={filteredModels}
        years={filteredYears}
        prices={filteredPrices}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />

      {/* New Cars Section */}
      <h2
        id="newcars"
        className="flex justify-center items-center text-3xl font-extrabold mt-5 mb-4 text-white bg-gradient-to-r 
        from-blue-800 to-teal-500 p-1 rounded-lg shadow-xl w-full md:w-1/3 mx-auto text-center cursor-pointer transition-all duration-300 
        ease-in-out transform hover:bg-blue-700 hover:text-white hover:scale-105">
        New Cars
      </h2>
      {Array.isArray(filteredNewCars) && filteredNewCars.length > 0
        ? renderCarCards(filteredNewCars)
        : <p>No new cars available at this time.</p>}

      {/* Used Cars Section */}
      <h2
        id="usedcars"
        className="flex justify-center items-center text-3xl font-extrabold mt-5 mb-4 text-white bg-gradient-to-r 
            from-blue-800 to-teal-500 p-1 rounded-lg shadow-xl w-full md:w-1/3 mx-auto text-center cursor-pointer transition-all duration-300 
            ease-in-out transform hover:bg-blue-700 hover:text-white hover:scale-105">
        Used Cars
      </h2>
      {Array.isArray(filteredUsedCars) && filteredUsedCars.length > 0
        ? renderCarCards(filteredUsedCars)
        : <p>No used cars available at this time.</p>}

      {/* Inquiry Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Inquiry about {selectedCar ? selectedCar.name : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
              className="w-full border p-2 mb-2"
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
              className="w-full border p-2 mb-2"
            />
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Your Phone"
              className="w-full border p-2 mb-2"
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Your Message"
              className="w-full border p-2 mb-2"
            />
            <button type="submit" className="w-full bg-blue-900 text-white p-2">
              Send Inquiry
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );

};

export default Cards;
