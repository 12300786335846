import React, { useEffect, useState } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import ContactForm from './ContactForm.js';
import Header from './Header.js';
import Cards from './Cards.js';
import 'bootstrap/dist/css/bootstrap.min.css';

const images = [
  '/images/cars/blue.jpg',
  '/images/cars/white.jpg',
  '/images/cars/black.jpg',
];
const App = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-cream-50 p-4 md:p-8">
      
      {/* Header */}
      <Header />
    
      <div id="home" className="mt-24">
        {/* Carousel */}
        <div className="relative w-11/12 max-w-8xl h-160 mb-2 overflow-hidden mx-auto">
          <img
            src={images[currentIndex]}
            alt={`Slide ${currentIndex + 1}`}
            className="w-full h-full object-cover transition-opacity duration-500" // Full width and height with cover
            style={{ width: '1500px', height: '500px' }} // Fixed dimensions (adjust as needed)
          />
        </div>

        {/* Dots for Navigation */}
        <div className="flex justify-center space-x-2 mb-4">
          {images.map((_, index) => (
            <button key={index} className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-blue-900' : 'bg-gray-300'}`} onClick={() => setCurrentIndex(index)} />
          ))}
        </div>

        {/* Sections for Each Property Category */}
        <Cards />

        {/* Address and Contact Form */}
        <ContactForm />

        {/* Footer */}
        <footer className="bg-cream-50 p-2 text-black flex flex-col items-center">
          <div className="flex space-x-2 mb-2">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} className="text-blue-900" />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} className="text-blue-900" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="text-blue-900" />
            </a>
          </div>
          <div className="text-center">
            <p>
              &copy; 2024 <a href="https://hellolocal.in" target="_blank" rel="noopener noreferrer" className="text-purple-900 hover:underline">HelloLocal</a>. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default App;
