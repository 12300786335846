import React from 'react';

const CarFilters = ({ makes, models, years, prices, selectedFilters, setSelectedFilters }) => {
  return (
    <div className="flex flex-wrap justify-center mb-4 space-x-4 space-y-2 md:space-y-0">
      <select
        className="border rounded p-2 w-full md:w-auto"
        value={selectedFilters.make}
        onChange={(e) => {
          const selectedMake = e.target.value;
          setSelectedFilters({
            make: selectedMake,
            model: '',
            year: '',
            price: ''
          });
        }}
      >
        <option value="">Select Make</option>
        {makes.map((make) => (
          <option key={make} value={make}>{make}</option>
        ))}
      </select>

      <select
        className="border rounded p-2 w-full md:w-auto"
        value={selectedFilters.model}
        onChange={(e) => setSelectedFilters({ ...selectedFilters, model: e.target.value })}
      >
        <option value="">Select Model</option>
        {models.map((model) => (
          <option key={model} value={model}>{model}</option>
        ))}
      </select>

      <select
        className="border rounded p-2 w-full md:w-auto"
        value={selectedFilters.year}
        onChange={(e) => setSelectedFilters({ ...selectedFilters, year: e.target.value })}
      >
        <option value="">Select Year</option>
        {years.map((year) => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>

      <select
        className="border rounded p-2 w-full md:w-auto"
        value={selectedFilters.price}
        onChange={(e) => setSelectedFilters({ ...selectedFilters, price: e.target.value })}
      >
        <option value="">Select Price</option>
        {prices.map((price) => (
          <option key={price} value={price}>{price}</option>
        ))}
      </select>
    </div>
  );
};

export default CarFilters;